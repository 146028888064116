import DownloadLink from '../constants/download-link';
import { randomNum } from '../common/index';

/**
 * @description: 下载模块
 * @return {string} download link
 **/

class Download {
  constructor() {}

  downLoad(packageName = 'fission', options = { mode: 'default' }) {
    const { mode } = options;

    if (mode === 'divide') {
      return this.$_randomDownload();
    }

    return this.downLoadAPK(packageName);
  }

  /**
   * @description 按权重随机下载 APK
   */

  $_randomDownload() {
    const random = randomNum(1, 100);

    if (random <= 50) {
      return this.downLoadAPK('fission');
    }
    return this.downLoadAPK('ab1');
  }

  /**
   * @description 下载APK
   * @param  {string} packageName - 包名
   */

  downLoadAPK(packageName) {
    let url = '';

    switch (packageName) {
      case 'fission':
        url = DownloadLink.SHARE_FISSION;
        break;
      case '3ren8yuan':
        url = DownloadLink.SHARE_FISSION;
        break;
      case 'level_reward':
        url = DownloadLink.SHARE_FISSION;
        break;
      case 'share_link_music':
        url = DownloadLink.SHARE_LINK_MUSIC;
        break;
      case 'share_link_video':
        url = DownloadLink.SHARE_LINK_VIDEO;
        break;
      case 'share_ios':
        url = DownloadLink.SHARE_IOS;
        break;
      case 'learn_make_money':
        url = DownloadLink.SHARE_LEARN_MAKE_MONEY;
        break;
      case 'limit_task':
        url = DownloadLink.SHARE_LIMIT_TASK;
        break;
      case 'kuaiyinyue':
        url = DownloadLink.SHARE_KUAIYINYUE;
        break;
      case 'ab1':
        url = DownloadLink.SHARE_LINK_AB1;
        break;
      case 'ab2':
        url = DownloadLink.SHARE_LINK_AB2;
        break;
      case 'yyb':
        url = DownloadLink.SHARE_YYB;
        break;
      case 'mb':
        url = DownloadLink.SHARE_MOBLIE_SITE;
        break;
      default:
        url = DownloadLink.SHARE_FISSION;
    }

    return url;
  }
}

export default new Download();
