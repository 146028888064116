<template>
  <div class="intro-modal" @click.stop="close">
    <img src="@/assets/images/common/intro.png" alt="intro" />
  </div>
</template>

<script>
  export default {
    name: 'IntroModal',
    data() {
      return {
        isShow: true, // 是否有欢迎弹窗
      };
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="less" scoped>
  .intro-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999999;
    img {
      position: absolute;
      height: 0.22rem;
      right: 0.1rem;
      width: 2.64rem;
      height: 2.19rem;
    }
  }
</style>
