<template>
  <div class="modal" v-if="show">
    <div class="modal-container">
      <div class="title"></div>
      <section>
        <h4>如何参与活动：</h4>
        <p>1）点击【立即参与】选择你的家乡；</p>
        <p>2）完成家乡绑定后，获得一张公益海报，快音帮你支持公益事业；</p>
        <p>3）自动计算从活动开始起，你在快音听歌时长，计作贡献值；</p>
        <p>4）【喊老乡冲榜】通过海报，每邀请1位好友，可获得30分钟听歌贡献值。好友进入活动页面则计为成功。</p>
      </section>
      <section>
        <h4>超级奖励：</h4>
        <p>1）家乡排名第一+贡献值第一，获得200现金</p>
        <p>2）家乡排名第一+贡献值第二、三，获得168现金</p>
        <p>3）家乡排名第二-三+贡献第一、二、三，获得88现金</p>
      </section>
      <section>
        <h4>基础奖励：</h4>
        <p>1）参与即可获得 公益海报一张（快音捐赠公益基金，你不需要额外支付费用）</p>
        <p>2）听歌60分钟：188金币</p>
      </section>
      <section>
        <h4>活动时间：</h4>
        <p>开始时间：2024年9月27日09:00</p>
        <p>结束时间：2024年10月6日23:59</p>
        <p>公示时间：2024年10月7日</p>
        <p>发奖时间：2024年10月8日～10月11日</p>
        <p>*逾期不可领取奖励</p>
      </section>
      <section>
        <h4>其它说明：</h4>
        <h5>1、贡献说明</h5>
        <p>1）听歌1分钟=1贡献值</p>
        <p>2）快音任意场景听歌均计入</p>
      </section>
      <section>
        <h5>2、排行榜说明</h5>
        <p>1）全国听歌排行榜，每60分钟更新1次。听歌时长（贡献值）相同时，按参与人数降序排列；</p>
        <p>2）家乡贡献值排行榜，每60分钟更新1次。听歌时长（贡献值）相同时，按参与时间先后排列。</p>
      </section>
      <section>
        <h5>3、注意事项</h5>
        <p>1）快音有权对用户的参与资格进行审核，对于审核未通过的用户，快音有权取消其参与活动的资格或获奖资格；</p>
        <p>2）由于参与用户自身过失、过错致使不能领取、兑换奖励的，快音不对由此造成的任何损失承担任何责任。</p>
      </section>
    </div>
    <img
      class="modal-close"
      src="https://static1.kaixinyf.cn//img/lza66e145b984810229065601.png"
      alt="close"
      @click="closeModal"
    />
  </div>
</template>

<script>
  export default {
    name: 'RuleModal',
    props: {},
    data() {
      return {
        show: false,
      };
    },
    computed: {},
    mounted() {},
    methods: {
      openApp() {
        this.$track({
          page_title: '一起听分享落地页',
          element_name: '弹窗_打开 或者是 弹窗_下载',
        });
        let jump = () => {
          let url;

          if (this.$platform.Android) {
            url = Download.downLoad('yyb');
          } else if (this.$platform.iOS) {
            url = Download.downLoad('share_ios');
          } else {
            url = Download.downLoad('yyb');
          }
          window.location.href = url;
        };
        // 使用友盟 U-link
        if (this.umLink.isUmLinkInitSuccess) {
          return;
        }
        window.location.href = `kuaiyin://followRoomDetail?roomCode=${this.$route.query.room_code}&startColor=${this.$route.query.startColor}&endColor=${this.$route.query.endColor}`;
        setTimeout(() => {
          jump();
        }, 1000);
      },
      open() {
        this.show = true;
      },
      closeModal() {
        this.show = false;
      },
    },
  };
</script>

<style scoped lang="less">
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    &-container {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      padding: 0.4rem;
      overflow-y: scroll;
      width: 6rem;
      height: 8.96rem;
      background: #fffdf6;
      border-radius: 0.24rem;
      box-sizing: border-box;
      -ms-overflow-style: none; /* 适用于 IE 和 Edge */
      /* 隐藏滚动条 */
      &::-webkit-scrollbar {
        display: none;
      }
      .title {
        margin: 0 auto;
        width: 2.72rem;
        height: 0.56rem;
        background: url('https://static1.kaixinyf.cn/img/lza66e13ba35a5f5223340533.png') no-repeat center center;
        background-size: contain;
      }
      section {
        text-align: left;
        margin-top: 0.4rem;
        h4 {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 0.32rem;
          color: #333333;
        }

        h5 {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 0.28rem;
          color: #333333;
        }
        p {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 0.28rem;
          color: #333333;
          line-height: 0.44rem;
        }
      }
    }
    &-close {
      // position: absolute;
      margin-top: 0.4rem;
      width: 0.8rem;
      height: 0.8rem;
    }
  }
</style>
