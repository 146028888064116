<template>
  <div class="hometown">
    <img class="hometown-slogn" src="https://static1.kaixinyf.cn//img/lza66de93863876b296968042.png" />
    <div class="hometown-rule" @click="handleClickRule">活动规则</div>

    <div class="wrapper my-hometown">
      <div class="wrapper-top"></div>
      <div class="wrapper-content flex">
        <h5>我的家乡排名</h5>
        <p>第<span class="question">?</span>名</p>
        <a :href="buttonLink">
          <div class="button" @click="handleClick">
            立即参与
            <div class="button-label">赢现金</div>
          </div>
        </a>
      </div>
      <div class="wrapper-bottom"></div>
    </div>

    <div class="wrapper rank">
      <div class="wrapper-top"></div>
      <div class="wrapper-content">
        <h5>全国听歌排行榜</h5>
        <p>榜单每小时更新</p>
        <div class="rank-list">
          <div class="rank-list-item" v-for="(item, index) in rankList" :key="index">
            <div class="left">{{ index + 1 }}</div>
            <div class="right">
              <div class="right-top">{{ item.province }}</div>
              <div class="right-bottom">收听{{ item.time }}小时 ｜ {{ item.people }}位老乡</div>
            </div>
          </div>
          <div class="rank-list-cover"></div>
        </div>
      </div>
      <div class="wrapper-bottom"></div>
    </div>

    <RuleModal ref="ruleModal" />
    <IntroModal v-if="showIntro" @close="closeIntro" />
  </div>
</template>
<script>
  import RuleModal from './Modal.vue';
  import IntroModal from '@/components/Modal/IntroModal/index.vue';
  import Download from '@/utils/download';

  export default {
    components: { RuleModal, IntroModal },
    props: {},
    name: 'hometown',
    data() {
      return {
        rankList: [
          {
            province: '北京',
            time: 482983,
            people: 38928893,
          },
          {
            province: '上海',
            time: 382981,
            people: 38928892,
          },
          {
            province: '四川',
            time: 372523,
            people: 33256321,
          },
          {
            province: '山东',
            time: 372523,
            people: 33256321,
          },
          {
            province: '河南',
            time: 372523,
            people: 33256321,
          },
        ],
        showIntro: false,
        buttonLink: 'javascript:void(0);',
        uid: this.$route.query.uid || '',
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      if (!this.$platform.WeiXin && !this.$platform.QQ) {
        const SCHEME =
          this.$route.query.app_name && this.$route.query.app_name !== 'undefined'
            ? this.$route.query.app_name
            : 'kuaiyin';
        let web_url;

        if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
          web_url = `https://h5.rd.kaixinyf.cn/activity/national-day-2024?fullScreen=true&isNavHidden=true&uid=${this.uid}`;
        } else if (process.env.NODE_ENV === 'pub') {
          web_url = `https://h5.pub.kaixinyf.cn/activity/national-day-2024?fullScreen=true&isNavHidden=true&uid=${this.uid}`;
        } else if (process.env.NODE_ENV === 'production') {
          web_url = `https://h5.kaixinyf.cn/activity/national-day-2024?fullScreen=true&isNavHidden=true&uid=${this.uid}`;
        }
        if (this.$platform.Android) {
          web_url = encodeURIComponent(web_url);
        }
        // console.log('web_url', web_url);
        this.buttonLink = `${SCHEME}://web?web_url=${web_url}`;
      } else {
        this.buttonLink = 'javascript:void(0);';
      }
    },
    methods: {
      handleClickRule() {
        this.$refs.ruleModal.open();
      },
      handleClick() {
        try {
          let jump = () => {
            let url;
            if (this.$platform.WeiXin || this.$platform.QQ) {
              this.showIntro = true;
              document.body.style.overflow = 'hidden';
              return;
            } else {
              url = Download.downLoad('yyb');
              setTimeout(() => {
                window.location.href = url;
              }, 3000);
            }
          };
          jump();
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
      closeIntro() {
        this.showIntro = false;
        document.body.style.overflow = 'auto';
      },
    },
  };
</script>
<style scoped lang="less">
  .hometown {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background: url('https://static1.kaixinyf.cn//img/lza66de9bab213db075232214.png') no-repeat center top;
    background-color: #f8f0da;
    background-size: 100% auto;
    padding-bottom: 0.8rem;
    &-slogn {
      margin-top: 9.24rem;
      width: 4.18rem;
      height: 1.69rem;
    }
    &-rule {
      display: flex;
      align-items: center;
      justify-content: center;
      writing-mode: vertical-rl; /* 文字竖向排列，从右到左 */
      text-orientation: upright; /* 文字直立显示，而不是旋转 */
      position: absolute;
      top: 0.4rem;
      right: 0;
      width: 0.48rem;
      height: 1.2rem;
      background: linear-gradient(180deg, #ffb053 0%, #ff7a19 100%);
      border-radius: 0.12rem 0rem 0rem 0.12rem;
      border: 0.02rem solid #f7f0da;
      font-weight: 600;
      font-size: 0.24rem;
      color: #ffffff;
      line-height: 0.26rem;
      border-right: none;
    }
    .wrapper {
      width: 6.76rem;
      &-top {
        height: 0.52rem;
        background: url('https://static1.kaixinyf.cn//img/lza66deb4d45eee7082297167.png') no-repeat center center;
        background-size: 100% auto;
      }
      &-content {
        width: 6.76rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: url('https://static1.kaixinyf.cn//img/lza66deb51e4b197223912470.png') repeat-y center center;
        background-size: 100% 0.08rem;
        h5 {
          font-weight: 600;
          font-size: 0.4rem;
          color: #e4452f;
        }
        p {
          margin-top: 0.2rem;
          font-weight: 400;
          font-size: 0.28rem;
          color: #999999;
          .question {
            font-family: DINAlternate, DINAlternate;
            font-weight: bold;
            font-size: 0.8rem;
            color: #e4452f;
          }
        }
        .button {
          margin-top: 0.48rem;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.5rem;
          height: 0.84rem;
          background: url('https://static1.kaixinyf.cn//img/lza66e105a29675c205824106.png') no-repeat center center;
          background-size: 100% auto;
          font-weight: 600;
          font-size: 0.32rem;
          color: #ffffff;
          &-label {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: -0.24rem;
            width: 1.12rem;
            height: 0.48rem;
            background: #ffc519;
            border-radius: 0.24rem;
            font-weight: 600;
            font-size: 0.24rem;
            color: #744414;
          }
        }
      }
      &-bottom {
        width: 6.76rem;
        height: 0.52rem;
        background: url('https://static1.kaixinyf.cn//img/lza66deb4f49eef8080145364.png') no-repeat center center;
        background-size: 100% auto;
        font-weight: 600;
        font-size: 0.32rem;
        color: #ffffff;
      }
    }
    .my-hometown {
      margin-top: 0.59rem;
    }
    .rank {
      margin-top: 0.4rem;

      &-list {
        position: relative;
        width: 100%;
        padding: 0.4rem;
        box-sizing: border-box;
        &-cover {
          position: absolute;
          top: 0;
          left: 1%;
          width: 98%;
          height: 100%;
          background: linear-gradient(180deg, rgba(255, 253, 246, 0) 0%, rgba(255, 253, 246, 1) 50%);
          // background: linear-gradient( 180deg, #000000 0%, rgba(0,0,0,0) 100%);
        }
        &-item {
          margin-bottom: 0.4rem;
          display: flex;
          align-items: center;
          height: 0.84rem;
          .left {
            font-weight: 600;
            font-size: 0.32rem;
            color: #e4452f;
          }
          .right {
            margin-left: 0.4rem;
            display: flex;
            flex-direction: column;
            &-top {
              font-weight: 600;
              font-size: 0.32rem;
              color: #1a1a1a;
            }
            &-bottom {
              margin-top: 0.08rem;
              font-weight: 400;
              font-size: 0.24rem;
              color: #999999;
            }
          }
        }
      }
    }
  }
</style>
