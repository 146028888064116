/**
 * @description: 下载链接配置
 */

export default {
  SHARE_FISSION: 'https://static1.kaixinyf.cn/channels/kuaiyin_52115.apk',
  SHARE_LINK_MUSIC: 'https://static1.kaixinyf.cn/channels/share_link_1.apk',
  SHARE_LINK_VIDEO: 'https://static1.kaixinyf.cn/channels/share_link_1.apk',
  SHARE_LINK_AB1: 'https://static1.kaixinyf.cn/channels/share_link_ab1.apk',
  SHARE_LINK_AB2: 'https://static1.kaixinyf.cn/channels/share_link_ab2.apk',
  SHARE_LIMIT_TASK: 'https://static1.kaixinyf.cn/channels/share_link_1.apk',
  SHARE_LEARN_MAKE_MONEY: 'https://static1.kaixinyf.cn/channels/share_link_1.apk',
  SHARE_KUAIYINYUE: 'https://static1.kaixinyf.cn/channels/share_kuaiyinyue.apk',
  SHARE_IOS: 'https://apps.apple.com/cn/app/id1493374755',
  SHARE_YYB: 'https://a.app.qq.com/o/simple.jsp?pkgname=com.kuaiyin.player',
  SHARE_MOBLIE_SITE: 'https://h5.happyky.cn/mobile-site',
};
